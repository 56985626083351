<template>
    <div id="annexData" v-loading="loading">
        <el-row>
            <el-col :span="20">
                <nav>
                    <div class="Route">
                        <a @click="backTop()">全部资料</a>
                        <template v-for="(item, index) in fileHierarchy">
                            <span :key="index" @click="returnDirectory(item.id, index)">
                                <i class="el-icon-arrow-right"></i>
                                {{ item.data }}
                            </span>
                        </template>
                    </div>
                </nav>
            </el-col>
            <el-col :span="4" class="fileBtn">
                <button class="iconfont iconxiazai1" @click="batchDownload"></button>
            </el-col>
        </el-row>

        <div class="annexList">
            <div id="Attachment" class="Annex">
                <div style="overflow:auto;">
                    <div class="AnnexInfo" id="AnnexInfoContainer" options="Setting">
                        <table>
                            <tbody>
                                <template v-if="fileData && fileData.length>0">
                                    <tr v-for="(item, index) in fileData" :key="index" @click="seeFile(item)">
                                        <th style="width: 5%;">
                                            <svg v-if="item.FileType == 1" class="iconwenjianjia1" aria-hidden="true">
                                                <use xlink:href="#iconwenjianjia1"></use>
                                            </svg>
                                            <svg v-if="item.FileType == 2" class="iconwenjian" aria-hidden="true">
                                                <use xlink:href="#iconwenjian"></use>
                                            </svg>
                                        </th>
                                        <td style="width: 30%;">
                                            <span
                                                ref="content"
                                                class="fileName"
                                                :title="item.FileName"
                                            >{{ item.FileName }}</span>
                                        </td>
                                        <td style="width: 6%;">
                                            {{ bytesToSize(item.FileSize) }}
                                        </td>
                                        <td style="width: 22%;" class="btns">
                                            <button
                                                v-if="item.FileType == 2"
                                                class="iconfont iconxiazai1"
                                                title="下载"
                                                @click.stop="download(item)"
                                            ></button>
                                            <button
                                                class="iconfont iconicon_yulan"
                                                title="预览"
                                                @click.stop="viewFileClick(item)"
                                            ></button>
                                        </td>
                                        <td style="width: 21%;" :title="item.describe">
                                            {{ item.FileDescription }}
                                        </td>
                                        <td>{{ item.CreateDate }}</td>
                                    </tr>
                                </template>
                                <template v-else>
                                    <tr class="noeAnnexData">
                                        <td colspan="6">
                                            <p>
                                                暂无附件
                                            </p>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/assets/css/annexStyle.styl';
import utils from '@/utils/util.js';
// import { setTimeout } from 'timers';
export default {
    // 流程附件
    name: 'annex-data',
    components: {},
    props: {
        businessInstanceId: {
            type: [Array, Object, String ],
        },
    },
    data() {
        return {
            allAccessoriesData: [],
            // 面包屑导航数据
            fileHierarchy: [],
            // 附件数据集合
            fileData: [],
            // 预览图片ID集合
            picturePreview: [],
            // 当前父ID 新增文件夹使用
            ParentID: 0,
            // 当前父ID 上传附件使用，在文件夹上上传，和新增文件父id不一样
            currentFile: '0',
            // 上传文件加载中状态
            loading: false,
        };
    },
    created() {
        this.gitAnnexData(this.businessInstanceId);
    },
    watch: {},
    methods: {
        // 获取附件数据
        gitAnnexData(businessInstanceId) {
            this.loading = true;
            this.$axios
                .get(`/interfaceApi/basicplatform/simpleflow/filegroupinstance/${businessInstanceId}`)
                .then(res => {
                    this.allAccessoriesData = JSON.parse(JSON.stringify(res));
                    this.fileData = this.initFileData(this.allAccessoriesData, this.currentFile);
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 文件大小转换
        bytesToSize(fileByte) {
            const fileSizeByte = fileByte;
            let fileSizeMsg = '';
            if (fileSizeByte < 1048576) {
                fileSizeMsg = (fileSizeByte / 1024).toFixed(1) + 'KB';
            } else if (fileSizeByte === 1048576) {
                fileSizeMsg = '1MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024)).toFixed(1) + 'MB';
            } else if (fileSizeByte > 1048576 && fileSizeByte === 1073741824) {
                fileSizeMsg = '1GB';
            } else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) {
                fileSizeMsg = (fileSizeByte / (1024 * 1024 * 1024)).toFixed(1) + 'GB';
            } else {
                fileSizeMsg = '文件超过1TB';
            }
            return fileSizeMsg;
        },
        // 根据id过滤附件数据
        initFileData(newVal, val, type) {
            const initFileData = [];
            if (newVal && newVal !== null) {
                newVal.forEach(item => {
                    if (item.ParentID === val) {
                        if (type === 'FileID' && item.FileType !== '1') {
                            initFileData.push(item.FileID);
                        } else {
                            initFileData.push(item);
                        }
                    }
                });
            }
            return initFileData;
        },
        // 文件下载
        download(data) {
            if (data.preview_url) {
                const date = new Date();
                const endDate = new Date(data.expire);
                if (date.getTime() < endDate.getTime()) {
                    if (data.mime_type.indexOf('image/') === -1) {
                        window.location.href = data.download_url;
                    } else {
                        window.location.href = data.download_url;
                    }
                } else {
                    this.getFileDetailFun(data, 1);
                }
            } else {
                this.getFileDetailFun(data, 1);
            }
        },
        // 批量下载
        batchDownload() {
            this.allAccessoriesData.forEach((item, index) => {
                if (item.FileType === '2' && item.AFI_Code) {
                    this.download(item);
                }
            });
        },
        // 获取文件下载预览地址
        getFileDetailFun(data, type) {
            this.$axios
                .get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + data.AFI_Code)
                .then(res => {
                    this.fileData.forEach(item => {
                        if (item.AFI_Code === data.AFI_Code) {
                            item.expire = res.expire;
                            item.mime_type = res.mime_type;
                            if (res.mime_type.indexOf('image/') === -1) {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            } else {
                                item.preview_url = res.preview_url;
                                item.download_url = res.download_url;
                            }
                        }
                    });
                    if (type === 2) {
                        if (res.mime_type.indexOf('image/') === -1) {
                            window.open(res.preview_url, '_blank');
                        } else {
                            // this.imgUrl = res.preview_url);
                            // this.dialogVisible = true;
                            this.$toast({
                                title: true,
                                text: '图片预览',
                                type: 'eject',
                                width: '15.4rem',
                                t_url: 'picturePreview/index',
                                extr: {
                                    code: {
                                        imgSrc: [res.preview_url],
                                    },
                                    isImg: true,
                                },
                                viewPosition: 'components',
                            });
                        }
                        return;
                    }

                    if (res.mime_type.indexOf('image/') === -1) {
                        window.location.href = res.download_url;
                    } else {
                        window.location.href = res.download_url;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        //   预览
        viewFileClick(data) {
            if (data.AFI_Code) {
                if (data.preview_url) {
                    const date = new Date();
                    const endDate = new Date(data.expire);
                    if (date.getTime() < endDate.getTime()) {
                        if (data.mime_type.indexOf('image/') === -1) {
                            window.open(data.preview_url, '_blank');
                        } else {
                            this.imgUrl = data.preview_url;
                            this.dialogVisible = true;
                        }
                    } else {
                        this.getFileDetailFun(data, 2);
                    }
                } else {
                    this.getFileDetailFun(data, 2);
                }
            }
        },
        // 查看
        seeFile(data) {
            if (data.FileType !== 2 && data.FileType !== '2') {
                this.ParentID = data.FileID;
                this.currentFile = data.FileID;
                const newFileData = [];
                if (this.allAccessoriesData && this.allAccessoriesData !== null) {
                    this.allAccessoriesData.forEach(item => {
                        if (item.ParentID === data.FileID) {
                            newFileData.push(item);
                        }
                    });
                }
                this.fileData = newFileData;
                this.fileHierarchy.push(
                    {
                        data: data.FileName,
                        id: data.FileID,
                    }
                );
            }
        },
        // 返回顶层
        backTop() {
            this.fileData = this.initFileData(this.allAccessoriesData, '0');
            this.ParentID = '0';
            this.currentFile = '0';
            this.fileHierarchy = [];
        },
        // 返回上级目录
        returnDirectory(id, index) {
            this.fileData = this.initFileData(this.allAccessoriesData, id);
            this.fileHierarchy.splice(index + 1, this.fileHierarchy.length - index);
        },
    },
};
</script>

<style lang="stylus" scoped>
#annexData
    padding 0.14rem
</style>
